define(['app', 'gtmEvents', 'enhancedEcommerce', 'formTimer', 'siteObj'], function(app, gtmEvents, enhancedEcommerce, formTimer, siteObj) {

  var GtmScript = function() {

    var self = this;

    function init() {
      var body = document.getElementsByTagName('body')[0];
      var isProduct = siteObj.type === 'product' || siteObj.page === 'product';
      var isList = siteObj.type === 'list';
      var isPageNotFound = app.element.hasClass('pagenotfound', body);

      try {
        // Widget tracking
        app.select('.trackwidget a, a.trackwidget').on('click', function() {
          app.publish('tracking/widget', this);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'widget tracking', e);
      }

      try {
        // PYOP tracking
        app.select('.bundle-button a').on('click', function() {
          app.publish('tracking/pyop', this);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'pyop tracking', e);
      }

      try {
        // Set up action to record .list page page 404
        if (isPageNotFound) {
          app.publish('tracking/record', 'List page 404:' + siteObj.siteCode, 'URL:' + window.location.pathname);
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', '404', e);
      }

      try {
        // Set up action to record .list page page empty
        if (isList && document.querySelectorAll('.emptylist').length) {
          app.publish('tracking/record', 'List Empty:' + siteObj.siteCode, 'URL:' + window.location.pathname);
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'List Empty', e);
      }


      try {
        if (isProduct && document.querySelectorAll('.related').length > 0) {
          app.element.on('click', function() {
            var productId = app.select(this).getAttribute('rel');
            app.publish('tracking/record', 'Related Product Clicked: ' + siteObj.siteCode, 'Product ID: ' + productId);
          }, document.querySelectorAll('.productlist .item a'));
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Related Product Clicked', e);
      }

      try {
        // Set up action to record clicks on PAP product icons on list pages
        app.select('.item .item-promo-icon a').on('click', function() {
          app.publish('tracking/record', 'Related Product Clicked: ' + siteObj.siteCode, 'Product ID: ' + app.select(this).closest('.item').getAttribute('rel'));
          var element = this;
          var parent = app.element.closest('.item', element);
          var parentAttr = app.element.getAttribute('rel', parent);
          var elementTxt = app.element.getText(element);
          var productAnchor = parent.querySelector('.product-name a');
          var productTitle = app.element.getAttribute('title', productAnchor);
          var productTxt = app.element.getText(productAnchor);

          app.publish('tracking/record', 'promotion product icon - ' + elementTxt, parentAttr, productTitle, productTxt);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'promotion product icon', e);
      }

      try {
        // Set up action to record clicks on PAP product text on list pages
        app.select('.item .product-promo-text a, .item .item-promo a').on('click', function() {
          var element = this;
          var parent = app.element.closest('.item', element);
          var parentAttr = app.element.getAttribute('rel', parent);
          var elementTxt = app.element.getText(element);
          var productAnchor = parent.querySelector('.product-name a');
          var productTitle = app.element.getAttribute('title', productAnchor);
          var productTypeText = app.element.getText(true, parent.querySelector('.product-name .product-type'));

          app.publish('tracking/record', 'promotion product text - ' + elementTxt, parentAttr, productTitle, productTypeText);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'promotion product text', e);
      }

      try {
        // Set up action to record clicks for you might like products
        app.select('.youmightlike .item a').on('click', function() {
          var element = this;
          var parent = app.element.closest('.item', element);
          var parentAttr = app.element.getAttribute('rel', parent);
          var youMightLikeTxt = app.element.getText(parent.querySelector('.youmightlike .panel-head span'));
          var productTitle = app.element.trim(app.element.getAttribute('title', parent.querySelector('.product-name a')));
          var productTypeText = app.element.getText(true, parent.querySelector('.product-name .product-type'));

          app.publish('tracking/record', 'you might like', youMightLikeTxt, parentAttr + ' - ' + productTitle + productTypeText);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'you might like', e);
      }

      try {
        // Set up action to record clicks for related products
        app.select('.related .item a').on('click', function() {
          var element = this;
          var parent = app.element.closest('.item', element).element;
          var parentAttr = app.element.getAttribute('rel', parent);
          var panelHeadTxt = app.select(element).closest('.panel-head span').getText(true);
          var productTitle = app.element.trim(app.element.getAttribute('title', parent.querySelector('.product-name a')));
          var productTypeText = app.element.getText(true, parent.querySelector('.product-name .product-type'));

          app.publish('tracking/record', 'related', panelHeadTxt, parentAttr + ' - ' + productTitle + productTypeText);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'related', e);
      }

      try {
        // Set up action to record clicks for bought after viewing products
        app.select('.boughtafterviewing .item a').on('click', function() {
          var element = this;
          var parent = app.element.closest('.item', element).element;
          var parentAttr = app.element.getAttribute('rel', parent);
          var panelHeadTxt = app.element.getText(app.element.closest('.panel-head span', element), true);
          var productTitle = app.element.trim(app.element.getAttribute('title', parent.querySelector('.product-name a')));
          var productTypeText = app.element.getText(true, parent.querySelector('.product-name .product-type'));

          app.publish('tracking/record', 'bought after viewing', panelHeadTxt, parentAttr + ' - ' + productTitle + productTypeText);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'bought after viewing', e);
      }

      try {
        // Set up action to record clicks for basket up sell
        app.select('.basket .not-too-late-to-add .item a').on('click', function() {
          var element = this;
          var parent = app.element.closest('.item', element).element;
          var parentAttr = app.element.getAttribute('rel', parent);
          var notTooLateTxt = app.element.getText(true, document.querySelector('.not-too-late-to-add .panel-head .not-too-late'));
          var productTitle = app.element.trim(app.element.getAttribute('title', parent.querySelector('.product-name a')));
          var productTypeText = app.element.getText(true, parent.querySelector('.product-name .product-type'));

          app.publish('tracking/record', 'Basket Up Sell', notTooLateTxt, parentAttr + ' - ' + productTitle + productTypeText);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Basket Up Sell', e);
      }

      try {
        // Set up action to record clicks for facets open / close
        app.select('#facets-panel p.facets-title').on('click', function() {
          var element = this;
          app.publish('tracking/record', 'Facets', 'open / close panel', app.element.getAttribute('class', element).replace('facets-title facet-', ''));
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Facets open/close', e);
      }

      try {

        // Capture discount codes being added at the basket
        if (document.querySelectorAll('body.basket').length && (siteObj.basketMessage !== '' || siteObj.discountCodeApplied !== '')) {

          //Valid discount code
          if (siteObj.discountCodeApplied === 'discount.code.applied') {
            app.publish('tracking/record', 'Discount codes', 'Valid', siteObj.basketDiscountCode);
          }

          //Invalid discount code
          if (siteObj.basketMessage === 'discount.code.invalid') {
            app.publish('tracking/record', 'Discount codes', 'Not Valid', siteObj.basketDiscountCode);
          }

          //Expired discount code
          if (siteObj.basketMessage === 'discount.code.inactive') {
            app.publish('tracking/record', 'Discount codes', 'Inactive', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.badproduct') {
            app.publish('tracking/record', 'Discount codes', 'Not Applicable to Basket Content', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.better.offer.applied') {
            app.publish('tracking/record', 'Discount codes', 'Better Offer Applied', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.customer.invalidexpired') {
            app.publish('tracking/record', 'Discount codes', 'Not Valid', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.customer.invalidforuser') {
            app.publish('tracking/record', 'Discount codes', 'Not Valid', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.customer.not.logged.in') {
            app.publish('tracking/record', 'Discount codes', 'Customer Not Logged In', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.customer.notloggedin') {
            app.publish('tracking/record', 'Discount codes', 'Customer Not Logged In', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.expired') {
            app.publish('tracking/record', 'Discount codes', 'Expired', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.gift.not.in.stock') {
            app.publish('tracking/record', 'Discount codes', 'Gift Not In Stock', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.gift.offer.not.in.stock.postfix') {
            app.publish('tracking/record', 'Discount codes', 'Gift Not In Stock', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.gift.offer.not.in.stock.prefix') {
            app.publish('tracking/record', 'Discount codes', 'Gift Not In Stock', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.not.applied') {
            app.publish('tracking/record', 'Discount codes', 'Not Applicable to Basket Content', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.unqualified') {
            app.publish('tracking/record', 'Discount codes', 'Not Applicable to Basket Content', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage === 'discount.code.Not.Applicable.to.Basket.Content') {
            app.publish('tracking/record', 'Discount codes', 'Not Applicable to Basket Content', siteObj.basketDiscountCode);
          }

          if (siteObj.basketMessage || siteObj.discountCodeApplied) {
            app.publish('tracking/record', 'Basket Message', siteObj.basketMessage, siteObj.basketDiscountCode);
          }

        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Discount codes', e.message);
      }

      try {
        // Set up action to register clicks on banners in the different areas
        var bannerTrack = document.querySelectorAll('.bannertrack');
        var bannerParent = [];
        for (var i = 0; i < bannerTrack.length; i++) {
          bannerParent.push(bannerTrack[i].parentNode);
        }
        app.select(bannerParent).on('click', function() {
          var element = this.parentNode;
          var bannerName = app.element.getAttribute('alt', element.querySelector('img'));
          var bannerPosition = app.element.getAttribute('rel', element);
          if (bannerName === '') {
            bannerName = 'Misc';
          }
          app.publish('tracking/record', 'banner track', bannerPosition, bannerPosition + ' ' + bannerName);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'banner track', e);
      }

      try {
        // Set up action to register go to checkout clicks
        var checkoutSecureLinks = document.querySelectorAll('.checkoutStartForm');

        app.select(checkoutSecureLinks).on('click', function() {
          app.publish('tracking/record', 'Link', 'Go To Checkout');
        }, checkoutSecureLinks);
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Mobile go to checkout "form" tag', e.message);
      }

      try {
        // Set up action to register go to checkout clicks
        app.select(document.getElementsByTagName('a')).containsString('checkoutStart.account', 'href').on('click', function() {
          app.publish('tracking/record', 'Link', 'Go to Checkout');
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Link go to checkout "a" tag', e);
      }

      try {
        // Set up action to register go to basket clicks
        app.select(document.getElementsByTagName('a')).containsString('my.basket', 'href').on('click', function() {
          var element = this;
          var linkText = app.element.getText(true, element);

          if (linkText === '') {
            linkText = 'noText';
          }
          app.publish('tracking/record', 'Link', 'Go to Basket', linkText);
        }
        );
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Link go to basket', e);
      }

      try {
        // Set up action to register go to checkout clicks
        if (document.getElementsByTagName('button').length > 0) {
          app.select(document.getElementsByTagName('button')).containsString('gotocheckout', 'class').on('click', function() {
            app.publish('tracking/record', 'Link', 'Go To Checkout');
          });
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Link go to checkout button', e);
      }

      try {
        app.select('.carousel-upsell .productbuy').on('click', function() {
          app.publish('tracking/record', 'click', 'Added the product SKU (' + app.element.closest('.item', this).getAttribute('rel') + ') to the basket.');
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Added the product SKU', e);
      }

      try {
        if (typeof twttr !== 'undefined') {
          // track twitter events in google analytics
          var event_names = {
            'click': '',
            'tweet': '',
            'retweet': 'source_tweet_id',
            'follow': 'screen_name',
            'favorite': 'tweet_id'
          };

          var twitterEventHandler = function(intent_event) {
            if (intent_event) {
              var label = intent_event.type === 'click' ? intent_event.region : (intent_event.data) ? intent_event.data[event_names[intent_event.type]] : '';
              var thispage = window.location.href;
              app.publish('tracking/record', 'twitter', intent_event.type, label + ' - ' + thispage);
            }
          };

          for (var event_name in event_names) {
            if (Object.hasOwn(event_names, event_name)) {
              window.twttr.events.bind(event_name, twitterEventHandler);
            }
          }
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'twitter', e);
      }

      try {
        if (typeof FB !== 'undefined') {
          // track facebook likes in google analytics
          window.FB.Event.subscribe('edge.create', function(targetUrl) {
            app.publish('tracking/record', 'facebook', 'like', targetUrl);
          });

          // track facebook sends in google analytics
          window.FB.Event.subscribe('message.send', function(targetUrl) {
            app.publish('tracking/record', 'facebook', 'send', targetUrl);
          });

          //track facebook unlikes in google analytics
          window.FB.Event.subscribe('edge.remove', function(targetUrl) {
            app.publish('tracking/record', 'unlike', targetUrl);
          });
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'facebook tracking', e);
      }


      // Sticky social links on the hut
      try {
        var stickySocialItem = document.querySelectorAll('.sticky-social-item a');
        app.on('click', function() {
          var element = this;
          var type = element.querySelector('.sticky-social-text');
          app.publish('tracking/record', 'click', 'sticky social icon ' + app.element.getText(true, type));
        }, stickySocialItem);
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'sticky social icon', e.message);
      }

      // Track which checkout button is used
      try {
        var checkoutBtnTop = document.querySelectorAll('.gotocheckout-top');
        app.on('click', function() {
          app.publish('tracking/record', 'Basket | Navigation', 'Checkout', 'Checkout Button Top');
        }, checkoutBtnTop);
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Checkout Button Top', e.message);
      }

      try {
        var checkoutBtnBottom = document.querySelectorAll('.gotocheckout-bottom');
        app.on('click', function() {
          app.publish('tracking/record', 'Basket | Navigation', 'Checkout', 'Checkout Button Bottom');
        }, checkoutBtnBottom);
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Checkout Button Bottom', e.message);
      }

      // Add tracking to see if overlay is viewed
      try {
        if (siteObj.page === 'basket') {
          var viewQuickview = document.querySelectorAll('.quickview-buy');
          app.on('click', function() {
            var element = this;
            var btnHref = element.getAttribute('href');
            var array = self.splitOn(btnHref, '/');
            var product = array[4];
            var skuPage = array[5];
            var sku = self.splitOn(skuPage, '.')[0];
            app.publish('tracking/record', 'Basket | Recommendation', 'Overlay Viewed', product + ' | ' + sku);
          }, viewQuickview);
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Overlay Viewed', e.message);
      }

      //tracking for delivery info table on lf basket
      try {
        var basketDeliverySelect = document.getElementById('basket-delivery-options');
        var oldCountry = siteObj.customerLocation;

        app.on('change', function() {
          var newCountry = basketDeliverySelect.options[basketDeliverySelect.selectedIndex].value;
          app.publish('tracking/record', 'Testing', 'LF Basket Test', oldCountry + ' | ' + newCountry);
        }, basketDeliverySelect);
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Delivery Country Change', e.message);
      }

      //tracking for back to mobile link
      try {
        var backToMobile = document.querySelectorAll('.back-to-mobile a');
        app.on('click', function() {
          app.publish('tracking/record', 'Back to mobile', 'click');
        }, backToMobile);
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'Back to mobile', e.message);
      }

      //Add to basket

      var defaultSelector = document.querySelectorAll('.js-ajax-basket').length ? '.js-basket, .js-ajax-basket' : null;

      try {
        if (siteObj.page === 'basket' && siteObj.features.enhancedEcommerce === 'true') {
          //check for ajax basket
          var eeBasketTrackingAdd = document.querySelectorAll(defaultSelector || '.auto-basketaction-add');

          app.on('click', function(e) {
            enhancedEcommerce.sendValues(e, 'auto-basketaction-add', 'addToCart', 'add');
          }, eeBasketTrackingAdd);
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'add to cart', e.message);
      }

      //Remove from basket
      try {
        if (siteObj.page === 'basket' && siteObj.features.enhancedEcommerce === 'true') {

          var eeBasketTrackingDelete = document.querySelectorAll(defaultSelector || '.auto-basketaction-delete');

          app.on('click', function(e) {
            enhancedEcommerce.sendValues(e, 'auto-basketaction-delete', 'removeFromCart', 'remove');
          }, eeBasketTrackingDelete);
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'remove from cart', e.message);
      }

      // basket items to trash
      try {
        if (siteObj.page === 'basket' && siteObj.features.enhancedEcommerce === 'true') {

          var eeBasketTrackingTrash = document.querySelectorAll(defaultSelector || '.auto-basketaction-trash');

          app.on('click', function(e) {
            enhancedEcommerce.sendValues(e, 'auto-basketaction-trash', 'removeFromCart', 'remove', true);
          }, eeBasketTrackingTrash);
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'remove from cart', e.message);
      }


      // Enhanced e-commerce tracking for search and list pages
      try {
        if ((siteObj.type === 'list' || siteObj.section === 'search') && siteObj.features.enhancedEcommerce === 'true') {

          //delegate event to containing div
          var eeBasketTracking = document.querySelectorAll('.js-enhanced-ecommerce');

          app.on('click', function(e) {
            enhancedEcommerce.sendValues(e, 'productbuy', 'addToCart', 'add');
          }, eeBasketTracking);
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'add to cart', e.message);
      }


      // Enhanced e-commerce tracking for product pages

      try {
        if (siteObj.type === 'product' && siteObj.features.enhancedEcommerce === 'true') {

          var eeProductPageTracking = null;
          var buyButtonButton = document.querySelectorAll('.js-enhanced-ecommerce button.js-buynow');
          var buyButtonLink = document.querySelectorAll('.js-enhanced-ecommerce .productbuy');
          var clickedEl = null;

          if (buyButtonLink.length) {
            eeProductPageTracking = buyButtonLink;
            clickedEl = 'productbuy';
          } else if (buyButtonButton.length) {
            eeProductPageTracking = buyButtonButton;
            clickedEl = 'js-buynow';
          }

          if (eeProductPageTracking !== null) {
            app.on('click', function(e) {
              enhancedEcommerce.sendValues(e, clickedEl, 'addToCart', 'add');
            }, eeProductPageTracking);
          }
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'add to cart', e.message);
      }


      // Form field timing
      try {
        if (siteObj.features.formTimer) {
          var formTimerObject = document.querySelector('.js-formTimer');
          var ft = new formTimer();
          ft.init(formTimerObject);
        }
      } catch (e) {
        app.publish('tracking/record', 'gtmEventsError', 'form timer', e.message);
      }

      // Navigation Header Clicks
      try {
        // Widget tracking
        //Targeting navHeader to prevent adding eventHandlers blocking main thread
        app.select('.navHeader').on('click', function (e) {


          var target = e.target.closest('header a');

          if(target) {
            var navigationText = target.innerText || target.innerText || '';
            var navigationHref = target.href;


            app.publish('tracking/record', 'Navigation Header', 'Clicked ' + navigationText, navigationHref);
          }

          if(siteObj.config.useGa4EnhancedEcom === true && target) {

            const targetPosition = parseInt(target.dataset['jsNavLevel']);

            var menuDivision = localStorage.getItem('MenuLevel1') || '';
            var menuSubDivision = localStorage.getItem('MenuLevel2') || '';
            var menuDepartment = localStorage.getItem('MenuLevel3') || '';

            if(targetPosition === 2) {
              menuDepartment = target.innerText;
            } 
            else if (targetPosition === 3){

              menuSubDivision = target.closest('[data-js-element="subnav"]').getAttribute('aria-label');
              menuSubDivision = menuSubDivision && menuSubDivision.trim();
              menuDepartment = target.innerText;
            } 
            else {
              menuDivision = target.innerText;
            }

            let dataLayerValues = {
              'event' : 'navigation',
              'navigation_element' : 'Main Menu',
              'navigation_type': 'Main Menu Click',
              'menu_division': menuDivision,
              'menu_sub_division' : menuSubDivision,
              'menu_department' : menuDepartment,
              'menu_level' : targetPosition,
            }

            app.publish('record/general', dataLayerValues);
            
          }

        });

      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'widget tracking', e);
      }


      // Product Page Thumbnails
      try {
        app.select('[data-track*="product-thumb-box"]').on('click', (e) => {

          const product = e.target.alt || app.element.closest('.product-title').innerText;
          const productCDN = self.splitSrc(e) || [];
          const lastItem = productCDN[productCDN.length - 1];
          const productID = self.splitOn(self.splitOn(lastItem, '.')[0], '-')[0];

          app.publish('tracking/record', 'Product Page Navigation', `${product} | Click Product Thumbnail`, productID);
        });
      } catch (e) {

        app.publish('javascript/error', 'gtmEventsError', 'Product Thumbnail Tracking');
      }

      // Product Page Main Image
      try {
        app.select('[data-track*="main-product-image"]').on('click', (e) => {

          let product = e.target.alt || e.target.querySelector('img').alt;
          let productCDN = self.splitSrc(e) || self.splitSrc(e.target.querySelector('img')) || [];
          let lastItem = productCDN[productCDN.length - 1];
          let productID = self.splitOn(self.splitOn(lastItem, '.')[0], '-')[0];

          app.publish('tracking/record', 'Product Page Navigation', `${product} | Click Main Product Image`, productID);
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Product Thumbnail Tracking');
      }

      // Google Ads
      try {
        if (document.querySelectorAll('[data-track*="ads-panel"]').length) {

          let page = window.location.host + window.location.pathname;

          app.publish('tracking/record', 'Adverts', `${page} | Ad Shown`);
        }
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'Ad Impressions Tracking');
      }

      // Product Image
      try {
        app.select('[data-track*="product-image"]').on('click', (e) => {

          let page = window.location.host + window.location.pathname;
          let productCDN = self.splitSrc(e) || self.splitSrc(e.target.querySelector('img')) || self.splitHref(e) || [];
          let lastItem = productCDN[productCDN.length - 1];
          let productID = self.splitOn(self.splitOn(lastItem, '.')[0], '-')[0];
          let path = self.splitOn(window.location.pathname, '.');

          // Only fire if on list page
          if (path[path.length - 1] === 'list') {
            app.publish('tracking/record', 'List Page Navigation', `${page} | Click Product Image`, productID);
          }
        });
      } catch (e) {
        app.publish('javascript/error', 'gtmEventsError', 'List Page Navigation Tracking');
      }

      // Carousel Arrow
      try {
        app.select('.rsArrow').on('click', () => {

          const page = window.location.hostname + window.location.pathname;
          const carouselID = `${page} Carousel`;

          app.publish('tracking/record', 'Carousel Navigation', `${page} | Click Arrow`, carouselID);
        });
      } catch (e) {
        app.publish('javascript/error', 'Carousel Navigation', 'click');
      }

      // Carousel Dot
      try {
        app.select('.rsBullet').on('click', () => {

          const page = window.location.hostname + window.location.pathname;
          const carouselID = `${page} Carousel`;

          app.publish('tracking/record', 'Carousel Navigation', `${page} | Click Dot`, carouselID);
        });
      } catch (e) {
        app.publish('javascript/error', 'Carousel Navigation', 'click');
      }

    //tracking end
    }

    function splitOn(text, separator) {
      return text ? text.split(separator || ' ') : [];
    }

    function splitSrc(elem) {
      var res = elem && elem.target ? self.splitOn(elem.target.src, '/') : [];
      return res.length ? res : null;
    }

    function splitHref(elem) {
      var res = elem && elem.target ? self.splitOn(elem.target.href, '/') : [];
      return res.length ? res : null;
    }

    self.init = init;
    self.splitOn = splitOn;
    self.splitHref = splitHref;
    self.splitSrc = splitSrc;
  };

  var gtm = new GtmScript();
  gtm.init();
  return gtm;
});
